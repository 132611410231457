import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"
import nvtLogo from "../images/new-vertical-logo.png"
import arrow from "../images/arrow-30.png"
import woman from "../images/stock-smiling-woman.jpg"
import man from "../images/stock-man-working.jpg"
import portrait from "../images/stock-portrait.jpg"

const AboutPage = () => (
  <Layout>
    <PageTitle title="Who We Are" page="PageTitleAbout" />
    <Seo title="About Us"
         description="Learn more about how we can help you solve technical problems related to your home or business.  We can support nearly all consumer and business technology."
         pathname={"/about/"}/>
    <div className="content-page-wrapper">
        <div className="page-intro">
            <h1>Our Team</h1>
            <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                <div className="col">
                    <img src={nvtLogo} alt="Image here"/>
                    <h3>Robert Chumley</h3>
                    <h4>CEO</h4>
                </div>
            </div>
            <div className="about-segment">
                <div className="about-left">
                    <img src={woman} alt="Image here"/>
                </div>
                <div className="about-right">
                    <h2>Our Mission</h2>
                    <p>At New Vertical Technologies, our goal is to make technology stress-free for you. Whether you
                        need prompt, reliable IT support and managed services, or innovative solutions for your
                        business, we strive to provide you with the services that you need to be stress-free.
                    </p>
                </div>
            </div>
            <div className="about-segment">
                <div className="about-left">
                    <h2>About Us</h2>
                    <p>Based in Kent Island, Maryland, New Vertical Technologies is a technology services firm dedicated
                        to providing clients with innovative managed service solutions and reliable customer support. By
                        providing our services, we aim to ease our clients' stress and make technology work for them.
                    </p>
                </div>
                <div className="about-right">
                    <img src={man} alt="Image here"/>
                </div>
            </div>
            <div className="about-segment">
                <div className="about-left-no-bottom-padding">
                    <h2>Why Choose Us</h2>
                    <p>At New Vertical Tech, we provide each client with a unique package of services, tailored to fit
                        their company's needs. </p>
                </div>
            </div>
            <div className="about-why-choose-us">
                <div className="why-row">
                    <div className="row align-items-center text-left">
                        <div className="col-sm-auto">
                            <img src={arrow} alt="Bullet"/>
                        </div>
                        <div className="col">
                            <h3>Business Consulting</h3>
                            <p>In addition to our services, we provide our clients with expert IT knowledge. We give our
                                clients more insight so that they can make informed decisions about their IT and software
                                needs.
                            </p>
                            <br></br>
                        </div>
                    
                        <div className="col-sm-auto">
                            <img src={arrow} alt="Bullet"/>
                        </div>
                        <div className="col">
                            <h3>Customer Support</h3>
                            <p>Our goal is that you are completely satisfied with our services. If you have any issues,
                                reach out, and we will do everything we can to resolve them.</p>
                            <br></br>
                            <br></br>
                        </div>

                    </div>
                    <div className="row align-items-center text-left">
                        <div className="col-sm-auto">
                            <img src={arrow} alt="Bullet"/>
                        </div>
                        <div className="col">
                            <h3>Proactive</h3>
                            <p>Network and device monitoring is an essential component of our services. We actively
                                monitor your systems so that we can identify and address issues before they become
                                problems.
                            </p>
                            <br></br>
                        </div>
                        <div className="col-sm-auto">
                            <img src={arrow} alt="Bullet"/>
                        </div>
                        <div className="col">
                            <h3>Everything You Need</h3>
                            <p>
                                When you choose New Vertical Technologies, your search for IT and software support is
                                over. We handle every piece of your IT infrastructure, including maintenance, security,
                                software development, project management, and anything else you can think of.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center text-left">
                        <div className="col-sm-auto">
                            <img src={arrow} alt="Bullet"/>
                        </div>
                        <div className="col">
                            <h3>Experience</h3>
                            <p>Our technical support team has decades of experience in the managed services industry.
                                When you use our services, you're being assisted by a seasoned IT expert.</p>
                            <br></br>
                            <br></br>
                        </div>
                        <div className="col-sm-auto">
                            <img src={arrow} alt="Bullet"/>
                        </div>
                        <div className="col">
                            <h3>Responsive</h3>
                            <p>In our industry, response time is essential. Issues with technology can seriously
                                compromise your business. We understand this, and will always get back to you quickly.
                                Just give us a call when you need us.
                            </p>
                            <br></br>
                        </div>
                    </div>
                </div>

            </div>
        </div> 
    </div>        
  </Layout>
)

export default AboutPage
